<template>
  <div style="background:#fff; height: 100%;">
    <el-form :inline="true"  @input="change($event)" :model="SqlResult"  ref="SqlResult" class="dynamicForm">
      <el-col >
        <el-form-item label="模版" prop="template_id" :rules="rules.template_id">
          <el-select  v-model="SqlResult.template_id"  @change="templatChage"  style="width:65%" placeholder="请选择短信模版">
            <el-option v-for="item in smsTemplates" :key="item.template_id" :label="item.template_title" :value="item.template_id" :disabled="item.disabled"></el-option>
          </el-select>
          <el-input type="textarea"  :autosize="{ minRows: 4, maxRows: 6 }" style="min-width: 350px;" v-model="smsTemplatContent"  readonly> </el-input>
        </el-form-item>
      </el-col>
      <div v-for="(item,index) in array" :key="index">
        <el-form-item prop="column" label="条件">
          <el-select v-model="item.column" filterable placeholder="请选择条件" @change="ChangeDataTime(item)">
            <el-option v-for="(item,index) in SqlParams"  :key="index" :label="item.cn_name" :value="item.en_name" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="operator" label="运算符">
          <el-select v-model="item.operator" clearable filterable placeholder="请选择sql符号">
            <el-option v-for="(item,index) in operator"  :key="index" :label="item.label" :value="item.label">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value" label="值" v-show="!item.show" >
          <el-input  v-model="item.value"   placeholder="请输入值"> </el-input>
        </el-form-item>
        <el-form-item prop="value" label="值" v-show="item.show"> 
          <el-date-picker 
            v-model="item.value" 
            type="datetime" 
            :clearable="false" 
            placeholder="选择日期时间"  
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" @click="addInput">添加</el-button>
          <el-button type="danger" icon="el-icon-delete" :disabled="array.length < 2" @click="delInput(index)">删除</el-button>
        </el-form-item>
      </div>
      <div :inline="true"  @input="change($event)"  v-for="(item, index) in SqlResult.array" :key="index">
        <el-form-item prop="column">
          <el-select v-model="item.column" clearable filterable placeholder="请选择"   >
            <el-option v-for="(item,index) in SqlParams"  :key="index" :label="item.cn_name" :value="item.en_name"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="operator" >
          <el-select v-model="item.operator" clearable filterable placeholder="请选择">
            <el-option v-for="(item,index) in operator"  :key="index" :label="item.label" :value="item.label"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value" label="值" v-show="!item.show">
          <el-input  v-model="item.value"   placeholder="请输入"> </el-input>
        </el-form-item>
        <el-form-item prop="value" label="值" v-show="item.show"> 
          <el-date-picker 
            v-model="item.value" 
            type="datetime" 
            :clearable="false" 
            placeholder="选择日期时间" 
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" v-preventReClick @click="addInput">添加</el-button>
          <el-button type="danger" icon="el-icon-delete" v-preventReClick @click="delInput(index) ">删除</el-button>
        </el-form-item>
      </div>
      <el-form-item  class="search">
        <el-button icon="el-icon-search" v-preventReClick @click="submitSearch" type="primary"  :disabled="saveDisable" >查询</el-button>
        <el-button icon="el-icon-refresh" v-preventReClick @click="resetForm()">重置</el-button>
      </el-form-item>
       
      <el-table :data="select_sql_result"  border  :header-cell-style="headClass"   v-loading="loading" id="table" > 
        <el-table-column  v-for="(item,key) in select_sql_result[0]" :key="key" align="center"  show-overflow-tooltip> 
          <template slot="header">{{key |filterConditions}}</template>
          <template slot-scope="scope">{{select_sql_result[scope.$index][key]}}</template>
        </el-table-column>
        <!-- <div slot="empty" class="empty">
          <p>
          <img src="https://ysf.nosdn.127.net/huke/501809b24ca822a32d8792d0e6872b3f" />
          </p>
          <p><span>暂无查询结果</span></p>
        </div> -->
      </el-table>
     <div>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="page_info.current_page"
        :limit.sync="page_info.page_size"
        @pagination="Search"
      />
    </div>
  </el-form>
  <el-form class="taskForm">
    <el-form-item>
      <el-button type="text" @click="createTiming()" v-preventReClick class="taskFontSize"><i class="el-icon-alarm-clock"></i> 定时任务</el-button>
      <el-button type="text" @click="intervalTime()" v-preventReClick class="taskFontSize"><i class="el-icon-timer"></i> 周期任务</el-button>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import {errorAlert,successAlert}from "../../../utils/alert"
  export default {
     props: ["row"],
    data() {
      return {
          smsTemplatContent: "", 
         disabledStatus:false,
           saveDisable: true, 
          showUrl:false,
          job_id:"",
          input:'',
          value:'',
          value1:'',  
          operator: [{value:'等于',label:'=='},{value:'大于等于',label:'>='},{value:'小于等于',label:'<='}, {value: '大于',label: '>'},{value: '小于',label: '<'},{value: '不等于',label: '!='},{value: '包含',label: 'like'}],
          conditions:[], 
          SqlParams:[], 
          smsTemplates:[], 
          smsTemplatContent:"", 
          SqlResult:{template_id:"",conditions:[],},
          array:[{column:'',operator:'',value:'',show:false}],
              count:1,
              select_sql_result:[],
              sql:[],
              obj:[],
              total: 0,
            page_info: { page_size: 10, current_page: 1},
          loading: false,
          rules:{
            column:[{required: true, message: '请选择查询条件',trigger:['blur','change']}],
            operator:[{required: true, message: '请选择运算符',trigger:['blur','change']}],
            value:[{ required: true, message: '请输入值' ,trigger:['blur','change']}],
            template_id:[{required: true, message: '请选择短信模版', trigger: 'change'}],
            },
      }
    },
    
    watch:{
      array:{
        handler:function(newVal,oldVal){
          this.saveDisable=false
          for (let i in newVal){
            if(newVal[i].column===''){
              this.saveDisable = true 
            }
            if(newVal[i].operator===''){
              this.saveDisable = true 
            }
             if(newVal[i].value===''){
              this.saveDisable = true 
            }
          }
        },
        deep:true
      },
      
    },

    created(){
      this.getSqlParams();
      this.getSmsTemplates();
    },
    
   methods: {
      ChangeDataTime(item){
        if(item.column === "last_order_createtime" || item.column==="first_order_createtime"){
          item.show = true
          item.value=""
        }else{
          item.show=false
          item.value=""
        }
      },
     
      createTiming(row) {
        let data = {
            template_id:this.SqlResult.template_id,
            sql_text:this.sql_text,
            smsTemplatContent:this.smsTemplatContent
          }
        window.openModal(
          () => import("./create-timing.vue"),
          { row:{...row,...data}},
          { success: ()=>{
          } }
        );
      },
   
        intervalTime(row){
          let data = {
            template_id:this.SqlResult.template_id,
            sql_text:this.sql_text,
            smsTemplatContent:this.smsTemplatContent
          }
          window.openModal(
            () => import("./create-cycle.vue"),
            { row:{...row,...data}},
            { success: ()=>{
            } }
          );
        },
    
        templatChage(row) {
          this.smsTemplates.forEach((item) => {
              if (item.template_id === row) {
                this.smsTemplatContent = item.template_content;
              }
            });
        },
       
        async getSmsTemplates() {
            const res = await this.$http.get("/message_template/list");
            this.smsTemplates = res.data.data.templates;
            this.smsTemplates.forEach((item) => {
              if(item.template_status != "2"){
                item.disabled = true;
              }
            })
          },

        async getSqlParams() {
          const res = await this.$http.get("/periodic_task/sql_builder_params/get");
          this.SqlParams = res.data.data.params;
        },
    
      headClass() {
        return 'background: #f5f5f7;border-bottom:none;color:rgba(0,0,0,.85)'
      },
   
        addInput() {
          this.array.push({column:'',operator:'', value:'',show:false})
        },
       
        delInput(index) {
          this.array.splice(index, 1)
        },

        submitSearch( ){
          this.$refs. SqlResult.validate((valid)=>{
            if (valid){
              this.Search()
              }
           })
        },

        async Search(){
          const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255,255,255,0.5)',
                target: document.querySelector('#table')
        });
            let a=JSON.parse(JSON.stringify(this.array));
              a.map(item=>{
            return delete item.show
            })
            let params = {
              template_id:this.SqlResult.template_id,
              conditions : a,
              current_page: this.page_info.current_page,
              page_size: this.page_info.page_size,
                };
              const res = await this.$http.post("/periodic_task/generate_sql_data/get",params);
                if (res.data.code ==0) {
                  this.select_sql_result=res.data.data.select_sql_result.sort(function (a,b){
                    return b.sum_order_pay-a.sum_order_pay;	
                  })
                      this.sql_text=res.data.data.sql;
                      this.total = res.data.data.total;
                        if(this.select_sql_result==""){
                        this.$message.warning("SQL查询结果为空")
                    }
                }else{
                    errorAlert(res.data.msg)
                }
              loading.close()  
        },
      
        resetForm(){
          this.SqlResult=this.$options.data().SqlResult;
          this.smsTemplatContent=this.$options.data().smsTemplatContent
          this.array=this.$options.data().array;
          this.select_sql_result=this.$options.data().select_sql_result;
          this.CreateTask=this.$options.data().CreateTask;
          this.getSqlParams()
          this.total=this.$options.data().total
          this.page_info=this.$options.data().page_info
       },
        },
    }
</script>
<style  scoped lang="scss">
/deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: ' ';
}
.search{
  float: right;
}
.el-form-item{
    margin-top: 20px;
}
.dynamicForm{
    background: #fff;
    width: 98%;
    padding: 0 10px 0 10px;
}
.taskForm{
  text-align: center;
   background: #fff;
      .taskFontSize{
      font-size: 22px;
      margin: 20px 50px 0 80px;
    }
}
 p{
    font-size: 12px;
    color: #666;
    font-weight: 400;
    width: 100%;
    text-align: center;
    line-height: 30px;
    position: absolute;
    bottom: 10px;
    border-top:1px solid #cae1ff ;
    span{
      margin: 20px;
      margin-top: 20px;
    }
    }
   /deep/.el-form-item--small .el-form-item__content {
    margin-right: 20px;
}
.el-table{
  color: #000;
}
.el-input--small {
  width: 190px;
}
/deep/.el-table__empty-block {
    min-height: 180px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty{
  /deep/ img{
  width: 200px;
  height: 180px;
}
  /deep/ p{
    text-align: center;
    line-height: 30px;
    position: absolute;
    bottom: -10px;
    left: 0;
    border-top: 0;
} 
}

</style>

